<template>
  <v-card>
    <v-card-title>契約(新規)</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div v-for="msg in apierror.messages" :key="msg">
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
      <v-stepper v-model="page">
        <v-stepper-header>
          <v-stepper-step
            :complete="page > 1"
            step="1"
          >
            概要
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="page > 2"
            step="2"
          >
            コンテナー
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            契約科目
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <template>
              <v-form class="ma-6 mb-0">
                <v-row align="center">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label
                      class="text-right"
                    >取引先<span style="color: red">*</span></label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="Form.cust_abbr"
                      :error-messages="custIdErrors"
                      readonly
                      outlined
                      dense
                      placeholder="取引先"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                  >
                    <Customer
                      :selected-customer="selectedCustomer"
                      @add_cuslist="getaddCusList"
                    ></Customer>
                  </v-col>
                </v-row>
                <v-row v-if="Form.cust_id">
                  <v-col
                    cols="12"
                    md="2"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    {{ Form.cust_id }}:{{ Form.cust_name }}
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    {{ Form.cust_name_eng }}
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label>契約日<span style="color: red">*</span></label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-menu
                      v-model="isDateMenuOpen"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      eager
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="Form.cont_date"
                          :error-messages="contDateErrors"
                          class="header-inputs flex-grow-0"
                          readonly
                          outlined
                          dense
                          placeholder="契約日"
                          hide-details="auto"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="Form.cont_date" @input="isDateMenuOpen = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label>マスターB/L</label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="Form.cont_bl"
                      outlined
                      dense
                      placeholder="マスターB/L"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label>インコタームズ<span style="color: red">*</span></label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-autocomplete
                      v-model="Form.ic_id"
                      :items="incotermNameId"
                      :error-messages="icIdErrors"
                      outlined
                      dense
                      placeholder="インコタームズ"
                      hide-details="auto"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label>貿易場所<span style="color: red">*</span></label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-autocomplete
                      v-model="Form.cont_abroad"
                      :items="contRanges"

                      outlined
                      dense
                      placeholder="貿易場所"
                      hide-details="auto"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label>通貨<span style="color: red">*</span></label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-autocomplete
                      v-model="Form.ccy_id"
                      :items="currencies"
                      outlined
                      dense
                      :error-messages="ccyIdErrors"
                      placeholder="通貨"
                      hide-details="auto"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label>目的地<span style="color: red">*</span></label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-autocomplete
                      v-model="Form.dest_id"
                      outlined
                      dense
                      placeholder="目的地"
                      :items="destinationNameId"
                      :error-messages="destIdErrors"
                      hide-details="auto"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label>出荷予定日</label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-menu
                      v-model="isSsdMenuOpen"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      eager
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="Form.cont_ssd"
                          class="header-inputs flex-grow-0"
                          placeholder="出荷予定日"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="Form.cont_ssd" @input="isSsdMenuOpen = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label>入金予定日<span style="color: red">*</span></label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-menu
                      v-model="isEddMenuOpen"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      eager
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="$v.Form.cont_edd.$model"
                          :error-messages="contractEddErrors"
                          class="header-inputs flex-grow-0"
                          placeholder="予定入金日"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="Form.cont_edd" @input="isEddMenuOpen = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label>連絡事項</label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="7"
                  >
                    <v-textarea
                      v-model="$v.Form.cont_attn.$model"
                      outlined
                      dense
                      :counter="200"
                      :error-messages="contAttnErrors"
                      placeholder="連絡事項"
                      hide-details="auto"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </template>
            <v-card-actions
              align="center"
              class="d-flex justify-center pt-10"
            >
              <v-btn
                color="primary"
                :loading="submitStatus"
                @click="toPage2(Form)"
              >
                次へ
              </v-btn>
              <v-btn
                outlined
                @click="cancelClick"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="2">
            <template>
              <v-form class="ma-6">
                <v-row v-if="containersErrors" align="center">
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <span style="color: red">コンテナーは必須項目！</span>
                  </v-col>
                </v-row>
                <v-row v-if="detailErrors" align="center">
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <span style="color: red">商品を確認してください！</span>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="12" class="pb-12">
                    <div class="add-products-form pt-4 pb-10">
                      <div
                        v-for="containers in Form.containers"
                        :key="containers.detail_id"
                        class="single-product-form"
                      >
                        <v-card outlined tile class="mb-6">
                          <v-toolbar
                            flat
                            height="36px"
                          >
                            <v-toolbar-title class="grey-text">
                              {{ containers.detail_id }}
                            </v-toolbar-title>

                            <v-spacer></v-spacer>
                            <v-btn icon small @click="menuUp(containers)">
                              <v-icon size="20">
                                {{ containers.showon? icons.mdiMenuUp:icons.mdiMenuDown }}
                              </v-icon>
                            </v-btn>
                            <v-btn icon small @click="removeContainer(containers)">
                              <v-icon size="20">
                                {{ icons.mdiClose }}
                              </v-icon>
                            </v-btn>
                          </v-toolbar>
                          <v-divider></v-divider>
                          <v-card-text>
                            <v-row align="center">
                              <v-col
                                cols="12"
                                md="3"
                              >
                                <v-text-field
                                  id="cntr_no"
                                  v-model="containers.cntr_no"
                                  outlined
                                  dense
                                  label="Container No."
                                  placeholder="Container No."
                                  hide-details="auto"
                                ></v-text-field>
                              </v-col>
                              <v-spacer></v-spacer>
                              <v-col
                                cols="12"
                                md="2"
                              >
                                <label for="actual_date">積込日</label>
                              </v-col>
                              <v-col
                                cols="12"
                                md="4"
                              >
                                <v-menu
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="packing_date"
                                      placeholder="積込日"
                                      dense
                                      hide-details="auto"
                                      outlined
                                      readonly
                                      v-bind="attrs"
                                      clearable
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="packing_date"
                                    @input="menu = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                            <v-row align="center">
                              <v-col
                                cols="12"
                                md="3"
                              >
                                <v-text-field
                                  id="seal_no"
                                  v-model="containers.seal_no"
                                  outlined
                                  dense
                                  label="Seal No."
                                  placeholder="Seal No."
                                  hide-details="auto"
                                ></v-text-field>
                              </v-col>
                              <v-spacer></v-spacer>
                              <v-col
                                cols="12"
                                md="2"
                              >
                                <label
                                  class="text-right"
                                  for="actual_time"
                                >積込時間</label>
                              </v-col>
                              <v-col
                                cols="12"
                                md="2"
                              >
                                <v-autocomplete
                                  v-model="packing_hour"
                                  :items="hours"
                                  outlined
                                  placeholder="時"
                                  hide-details="auto"
                                  dense
                                ></v-autocomplete>
                              </v-col>
                              <v-col
                                cols="12"
                                md="2"
                              >
                                <v-autocomplete
                                  v-model="packing_minute"
                                  :items="minutes"
                                  outlined
                                  placeholder="分"
                                  hide-details="auto"
                                  dense
                                ></v-autocomplete>
                              </v-col>
                              <!-- <v-col
                                  cols="12"
                                  md="2"
                                  class="d-flex align-center"
                                >
                                  <span class="text-center">合計重量:{{ formatData(containers.cntr_weight) }}KG</span>
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="2"
                                  class="d-flex align-center"
                                >
                                  <span class="text-center">合計金額:￥{{ formatData(containers.cntr_amount) }}</span>
                                </v-col> -->
                            </v-row>
                            <v-row align="center">
                              <v-col
                                cols="12"
                                md="2"
                              >
                                <v-text-field
                                  id="cntr_bl"
                                  v-model="containers.cntr_bl"
                                  outlined
                                  dense
                                  label="B/L"
                                  placeholder="B/L"
                                  hide-details="auto"
                                ></v-text-field>
                              </v-col>
                              <v-spacer></v-spacer>
                              <v-col v-if="containers.showon"
                                     cols="12"
                                     md="6"
                                     style="text-align: right"
                              >
                                <v-btn color="orange" class="mt-1" outlined @click="addPellet(containers)">
                                  Add Pellet
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-data-table
                            v-if="containers.showon"
                            ref="ArrivalActuals"
                            flat
                            class="class-on-data-table elevation-1 mt-4"
                            :headers="headers"
                            :items="containers.details"
                            fixed-header
                            hide-default-footer
                            :items-per-page="-1"
                          >
                            <template v-slot:[`item.pellet_id`]="{ item }">
                              <v-autocomplete
                                v-model="item.pellet_id"
                                placeholder="ペレットID"
                                :items="pelletIds"
                                :error-messages="pelletIdErrors(item, containers)"
                                hide-details="auto"
                                outlined
                                dense
                                @change="pelletIdChange(item, containers.details, containers)"
                              ></v-autocomplete>
                            </template>
                            <template v-slot:[`item.product_name`]="{ item }">
                              <v-text-field
                                v-model="item.product_name"
                                :error-messages="productNameErrors(item, containers)"
                                placeholder="商品名"
                                hide-details="auto"
                                dense
                              ></v-text-field>
                            </template>
                            <template v-slot:[`item.cntr_dtl_fcb`]="{ item }">
                              <NumberInput
                                v-model="item.cntr_dtl_fcb"
                                :error-messages="cntrDtlFCBErrors(item, containers)"
                                :outlined="true"
                                hide-details="auto"
                                suffix=""
                              ></NumberInput>
                            </template>
                            <template v-slot:[`item.cntr_dtl_weight`]="{ item }">
                              <NumberInput
                                v-model="item.cntr_dtl_weight"
                                :error-messages="cntrDtlWeightErrors(item, containers)"
                                :outlined="true"
                                hide-details="auto"
                                suffix=""
                              ></NumberInput>
                            </template>
                            <template v-slot:[`item.cntr_dtl_price`]="{ item }">
                              <PriceInputFixedComponent
                                v-model="item.cntr_dtl_price"
                                :fixed-num="2"
                                :error-messages="cntrDtlPriceErrors(item, containers)"
                                :prefix="ccy_mark"
                                hide-details="auto"
                                :outlined="true"
                              ></PriceInputFixedComponent>
                            </template>
                            <template v-slot:[`item.cntr_dtl_amount`]="{ item }">
                              <PriceInputFixedComponent
                                v-model="item.cntr_dtl_amount"
                                :fixed-num="2"
                                :error-messages="cntrDtlAmountErrors(item, containers)"
                                :prefix="ccy_mark"
                                hide-details="auto"
                                :outlined="true"
                              ></PriceInputFixedComponent>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                              <v-icon
                                class="mr-2"
                                color="red"
                                @click="deletePellet(item, containers.details)"
                              >
                                {{ icons.mdiMinusCircle }}
                              </v-icon>
                            </template>
                            <template v-slot:[`body.append`]>
                              <tr>
                                <td
                                  class="font-weight-bold"
                                  style="background: #fafafa"
                                  :colspan="3"
                                >
                                  <label>合計</label>
                                </td>
                                <td
                                  class="font-weight-bold"
                                  style="text-align: right; background: #fafafa"
                                >
                                  <label>{{ weightTotal(containers.details, containers) }}KG</label>
                                </td>
                                <td
                                  style="background: #fafafa"
                                  :colspan="1"
                                ></td>
                                <td
                                  class="font-weight-bold"
                                  style="text-align: right; background: #fafafa"
                                >
                                  <label>{{ amountTotal(containers.details, containers) }}</label>
                                </td>
                                <td
                                  style="background: #fafafa"
                                  :colspan="4"
                                ></td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-card>
                      </div>
                      <v-btn color="primary" class="mt-4" outlined @click="addContainer(Form)">
                        Add Container
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </template>
            <v-card-actions
              align="center"
              class="d-flex justify-center"
            >
              <v-btn
                color="primary"
                @click="toPage3(Form)"
              >
                次へ
              </v-btn>
              <v-btn
                text
                outlined
                @click="page = 1"
              >
                戻る
              </v-btn>
              <v-btn
                outlined
                @click="cancelClick"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-stepper-content step="3" class="px-0 mx-0">
            <template>
              <v-form class="px-0 mx-0">
                <v-card-text class="d-flex align-center flex-wrap pb-0">
                  <v-row>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-autocomplete
                        v-model="Form.tmpl_id"
                        placeholder="テンプレート"
                        :items="templateNameId"
                        outlined
                        hide-details="auto"
                        dense
                        class="user-search me-3 mb-4"
                        @change="change(Form.tmpl_id)"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-data-table
                  ref="Templates"
                  :headers="templateHeaders"
                  :items="Form.documents"
                  fixed-header
                  item-key="ci_id"
                  class="elevation-0"
                >
                  <template v-slot:[`item.cd_input`]="{ item }">
                    <v-text-field
                      v-model="item.cd_input"
                      outlined
                      dense
                      hide-details="auto"
                    >
                    </v-text-field>
                  </template>
                </v-data-table>
              </v-form>
            </template>
            <v-card-actions
              align="center"
              class="d-flex justify-center"
            >
              <v-btn
                color="primary"
                :loading="submitStatus"
                @click="submit(Form)"
              >
                Submit
              </v-btn>
              <v-btn
                text
                outlined
                @click="page = 2"
              >
                戻る
              </v-btn>
              <v-btn
                outlined
                @click="cancelClick"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </v-card>
</template>

<script>
import {
  mdiSendOutline,
  mdiPlus,
  mdiPencil,
  mdiClose,
  mdiCogOutline,
  mdiMinusCircle,
  mdiMenuUp,
  mdiMenuDown,
} from '@mdi/js'
import {
  ref,

  // reactive,
} from '@vue/composition-api'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'
import {
  required, minValue, maxLength,
} from 'vuelidate/lib/validators'
import Sortable from 'sortablejs'
import Customer from '@/components/Customer.vue'
import NumberInput from '@/components/Input/NumberInput.vue'
import PriceInputFixedComponent from '@/components/PriceInputFixedComponent.vue'

export default {
  components: {
    Customer,
    NumberInput,
    PriceInputFixedComponent,
  },
  setup() {
    const items = []
    const isDateMenuOpen = ref(false)
    const isSsdMenuOpen = ref(false)
    const isEddMenuOpen = ref(false)
    const editStatus = ref(false)
    const clients = ref([])

    const setEditStatus = () => {
      editStatus.value = true
      console.log('---------->setName', editStatus.value)
    }

    return {
      isDateMenuOpen,
      isSsdMenuOpen,
      isEddMenuOpen,
      items,
      editStatus,
      clients,
      icons: {
        mdiSendOutline,
        mdiPlus,
        mdiPencil,
        mdiMinusCircle,
        mdiClose,
        mdiCogOutline,
        mdiMenuUp,
        mdiMenuDown,
      },

      setEditStatus,
    }
  },
  data: () => ({
    page: 1,
    submitStatus: false,
    apierror: [],
    containersErrors: false,
    detailErrors: false,
    ccy_mark: '',
    ccy_code: '',
    selectedCustomer: [],
    packing_date: '',
    packing_hour: '',
    packing_minute: '',
    contRanges: [
      {
        text: '国内', value: 0,
      },
      {
        text: '海外', value: 1,
      },
    ],
    hours: [

      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',

    ],
    minutes: [
      '00',
      '05',
      '10',
      '15',
      '20',
      '25',
      '30',
      '35',
      '40',
      '45',
      '50',
      '55',
    ],
    Form: {
      cust_abbr: '',
      cust_id: '',
      cont_attn: '',
      cont_date: '',
      cont_bl: '',
      ccy_id: '',

      ic_id: '',
      ic_code: '',
      tmpl_id: '',
      dest_id: '',
      cont_ssd: '',
      cont_edd: '',
      containers: [
        {
          id: 1,
          detail_id: 1,
          product_id: '',
          cust_id: '',
          cntr_no: '',
          seal_no: '',
          cntr_bl: '',
          cntr_weight: 0,
          cntr_amount: 0,
          showon: true,
          details: [],
        },
      ],
      documents: [],
    },

    templateHeaders: [
      {
        text: 'No.',
        align: 'left',
        sortable: true,
        value: 'disp_order',
        width: '10%',
        fixed: true,
      },
      {
        text: '契約科目名',
        value: 'ci_name',
        sortable: true,
        align: 'left',
        width: '20%',
        fixed: true,
      },
      {
        text: '入力内容',
        value: 'cd_input',
        sortable: true,
        align: 'left',
        width: '70%',
        fixed: true,
      },
    ],
    TempForm: [],
  }),
  validations: {
    Form: {
      cust_abbr: {
        required,
      },
      cont_date: {
        required,
      },
      ic_id: {
        required,
      },
      ccy_id: {
        required,
      },
      dest_id: {
        required,
      },
      cont_attn: {
        maxLength: maxLength(200),
      },
      cont_edd: {
        required,
      },
      containers: {
        $each: {
          details: {
            $each: {
              pellet_id: {
                required,
              },
              product_name: {
                required,
              },
              cntr_dtl_fcb: {
                required,
                minValue: minValue(1),
              },

              cntr_dtl_weight: {
                required,
                minValue: minValue(0),
              },
              cntr_dtl_price: {
                required,
                minValue: minValue(0),
              },
              cntr_dtl_amount: {
                required,
                minValue: minValue(0),
              },
            },
          },
        },
      },
    },
  },
  computed: {
    ...mapState('pelletStore', ['pelletIds', 'pelletNameEn']),
    ...mapState('incotermStore', ['incotermNameId']),
    ...mapState('currencyStore', ['currencies']),
    ...mapState('destinationStore', ['destinationNameId']),
    ...mapState('templateStore', ['templateList', 'selectedTemplate', 'templateNameId']),

    headers() {
      return [
        {
          text: 'No.',
          align: 'left',
          sortable: false,
          value: 'cntr_did',
          width: '5%',
          fixed: true,
        },
        {
          text: 'Pellet',
          align: 'left',
          sortable: false,
          value: 'pellet_id',
          width: '20%',
          fixed: true,
        },
        {
          text: '商品名',
          align: 'left',
          sortable: false,
          value: 'product_name',
          width: '30%',
          fixed: true,
        },
        {
          text: '袋数(F)',
          align: 'right',
          sortable: false,
          value: 'cntr_dtl_fcb',
          width: '10%',
          fixed: true,
        },
        {
          text: '重量(KG)',
          value: 'cntr_dtl_weight',
          sortable: false,
          align: 'right',
          width: '10%',
          fixed: true,
        },
        {
          text: `単価(${this.ccy_code})`,
          value: 'cntr_dtl_price',
          sortable: false,
          align: 'right',
          width: '15%',
          fixed: true,
        },
        {
          text: '金額',
          value: 'cntr_dtl_amount',
          sortable: false,
          align: 'right',
          width: '15%',
          fixed: true,
        },
        {
          text: '　',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '5%',
          fixed: true,
        },
      ]
    },

    contractEddErrors() {
      const errors = []
      if (!this.$v.Form.cont_edd.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.cont_edd.required && errors.push('必須項目')

      return errors
    },

    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    custIdErrors() {
      const errors = []
      if (!this.$v.Form.cust_abbr.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.cust_abbr.required && errors.push('必須項目')

      return errors
    },
    contDateErrors() {
      const errors = []
      if (!this.$v.Form.cont_date.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.cont_date.required && errors.push('必須項目')

      return errors
    },
    icIdErrors() {
      const errors = []
      if (!this.$v.Form.ic_id.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.ic_id.required && errors.push('必須項目')

      return errors
    },
    ccyIdErrors() {
      const errors = []
      if (!this.$v.Form.ccy_id.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.ccy_id.required && errors.push('必須項目')

      return errors
    },
    destIdErrors() {
      const errors = []
      if (!this.$v.Form.dest_id.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.dest_id.required && errors.push('必須項目')

      return errors
    },
    contAttnErrors() {
      const errors = []
      if (!this.$v.Form.cont_attn.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.cont_attn.maxLength && errors.push('200文字以内にしてください。')

      return errors
    },

    // cntrNoErrors() {
    //   return function (item) {
    //     const errors = []
    //     if (item && item.detail_id) {
    //       const index = item.detail_id - 1
    //       if (!this.$v.Form.containers.$each[index].cntr_no.$dirty) return errors
    //       // eslint-disable-next-line no-unused-expressions
    //       !this.$v.Form.containers.$each[index].cntr_no.required && errors.push('必須項目')
    //     }

    //     return errors
    //   }
    // },
    // sealNoErrors() {
    //   return function (item) {
    //     const errors = []
    //     if (item && item.detail_id) {
    //       const index = item.detail_id - 1
    //       if (!this.$v.Form.containers.$each[index].seal_no.$dirty) return errors
    //       // eslint-disable-next-line no-unused-expressions
    //       !this.$v.Form.containers.$each[index].seal_no.required && errors.push('必須項目')
    //     }

    //     return errors
    //   }
    // },
    pelletIdErrors() {
      return function (item, items) {
        const errors = []
        if (item && item.cntr_did) {
          const index1 = items.detail_id - 1
          const index2 = item.cntr_did - 1
          if (!this.$v.Form.containers.$each[index1].details.$each[index2].pellet_id.$dirty) return errors
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.containers.$each[index1].details.$each[index2].pellet_id.required && errors.push('必須項目')
        }

        return errors
      }
    },
    productNameErrors() {
      return function (item, items) {
        const errors = []
        if (item && item.cntr_did) {
          const index1 = items.detail_id - 1
          const index2 = item.cntr_did - 1
          if (!this.$v.Form.containers.$each[index1].details.$each[index2].product_name.$dirty) return errors
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.containers.$each[index1].details.$each[index2].product_name.required && errors.push('必須項目')
        }

        return errors
      }
    },
    cntrDtlFCBErrors() {
      return function (item, items) {
        const errors = []
        if (item && item.cntr_did) {
          const index1 = items.detail_id - 1
          const index2 = item.cntr_did - 1
          if (!this.$v.Form.containers.$each[index1].details.$each[index2].cntr_dtl_fcb.$dirty) return errors
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.containers.$each[index1].details.$each[index2].cntr_dtl_fcb.required && errors.push('必須項目')
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.containers.$each[index1].details.$each[index2].cntr_dtl_fcb.minValue && errors.push('1以上にしてください')
        }

        return errors
      }
    },
    cntrDtlWeightErrors() {
      return function (item, items) {
        const errors = []
        if (item && item.cntr_did) {
          const index1 = items.detail_id - 1
          const index2 = item.cntr_did - 1
          if (!this.$v.Form.containers.$each[index1].details.$each[index2].cntr_dtl_weight.$dirty) return errors
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.containers.$each[index1].details.$each[index2].cntr_dtl_weight.required && errors.push('必須項目')
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.containers.$each[index1].details.$each[index2].cntr_dtl_weight.minValue && errors.push('1以上にしてください')
        }

        return errors
      }
    },
    cntrDtlPriceErrors() {
      return function (item, items) {
        const errors = []
        if (item && item.cntr_did) {
          const index1 = items.detail_id - 1
          const index2 = item.cntr_did - 1
          if (!this.$v.Form.containers.$each[index1].details.$each[index2].cntr_dtl_price.$dirty) return errors
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.containers.$each[index1].details.$each[index2].cntr_dtl_price.required && errors.push('必須項目')
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.containers.$each[index1].details.$each[index2].cntr_dtl_price.minValue && errors.push('0以外にしてください')
        }

        return errors
      }
    },
    cntrDtlAmountErrors() {
      return function (item, items) {
        const errors = []
        if (item && item.cntr_did) {
          const index1 = items.detail_id - 1
          const index2 = item.cntr_did - 1
          if (!this.$v.Form.containers.$each[index1].details.$each[index2].cntr_dtl_amount.$dirty) return errors
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.containers.$each[index1].details.$each[index2].cntr_dtl_amount.required && errors.push('必須項目')
          // eslint-disable-next-line no-unused-expressions
          !this.$v.Form.containers.$each[index1].details.$each[index2].cntr_dtl_amount.minValue && errors.push('0以外にしてください')
        }

        return errors
      }
    },
  },
  watch: {
    Form: {
      handler() {
        this.apierror.messages = []
      },
      deep: true,
    },

    'Form.ccy_id': {
      handler(val) {
        if (val) {
          const currency = this.currencies.find(c => c.value === val)
          this.ccy_mark = currency.mark
          this.ccy_code = currency.text.substr(currency.text.indexOf(':') + 1)

          console.log('currency:', currency, this.ccy_mark, this.ccy_code)
        }
      },
      deep: true,
    },

    'Form.containers': {
      handler(Val) {
        this.detailErrors = false
        if (this.Form.containers.length === 0) {
          this.containersErrors = true
        } else {
          this.containersErrors = false
        }

        // get cntr_dtl_amount
        for (let i = 0; i < Val.length; i += 1) {
          const newArray = Val[i].details.map(obj => ({
            ...obj, cntr_dtl_amount: (obj.cntr_dtl_price * obj.cntr_dtl_weight).toFixed(2),
          }))
          newArray.forEach(obj1 => {
            const matchingObj = this.Form.containers[i].details.find(obj2 => obj1.cntr_did === obj2.cntr_did)
            if (matchingObj) {
              matchingObj.cntr_dtl_amount = obj1.cntr_dtl_amount
            }
          })
        }
      },
      deep: true,
    },
  },

  // mounted() {
  //   this.loadData()
  // },
  created() {
    this.loadData()
  },
  mounted() {
    this.sortDocuments()
  },
  methods: {
    ...mapActions('pelletStore', ['loadPelletIds', 'loadPellets']),
    ...mapActions('contractStore', ['createContract']),
    ...mapActions('incotermStore', ['loadIncotermList']),
    ...mapActions('currencyStore', ['loadCurrencyList']),
    ...mapActions('destinationStore', ['loadDestinationList']),
    ...mapActions('templateStore', ['loadTemplates', 'loadTemplate', 'editTemplate']),
    ...mapMutations('app', ['setOverlayStatus']),
    sortDocuments() {
      const el = this.$refs.Templates.$el.querySelectorAll('.v-data-table__wrapper > table> tbody')[0]
      Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        sort: true,
        animation: 150,
        group: {
          name: 'Template',
          pull: false,
          put: false,
        },
        setData(dataTransfer, dragEl) {
          dataTransfer.setData('Text', dragEl.textContent)
        },
        onEnd: evt => {
          // console.log(evt.oldIndex, evt.newIndex)
          const item = this.Form.documents[evt.oldIndex]

          // console.log(item, this.Form.documents)
          this.Form.documents.splice(evt.oldIndex, 1)
          this.Form.documents.splice(evt.newIndex, 0, item)

          const documentsLidt = []
          this.Form.documents.forEach((v, i) => {
            documentsLidt[i] = v
            documentsLidt[i].disp_order = i + 1

            // console.log(i, v.disp_order, v.detail_id)
          })
        },
      })
    },
    weightTotal(item, items) {
      const index = this.Form.containers.indexOf(items)
      this.cntr_weight = 0
      item.forEach(obm => {
        if (obm.cntr_dtl_weight) {
          this.cntr_weight += Number(obm.cntr_dtl_weight)
        }
      })
      this.Form.containers[index].cntr_weight = this.cntr_weight

      return this.cntr_weight.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    },
    amountTotal(item, items) {
      const index = this.Form.containers.indexOf(items)
      this.cntr_amount = 0
      item.forEach(obm => {
        if (obm.cntr_dtl_amount) {
          this.cntr_amount += Number((Number(obm.cntr_dtl_amount)).toFixed(2))
        }
      })
      this.Form.containers[index].cntr_amount = Number(this.cntr_amount.toFixed(2))

      return this.cntr_amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
    },
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadPelletIds(),
        this.loadPellets(),
        this.loadTemplates(),
        this.loadIncotermList(),
        this.loadCurrencyList(),
        this.loadDestinationList(),
      ]).then(() => {
        this.initForm()
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    loadTemp(item) {
      this.setOverlayStatus(true)
      this.loadTemplate(item)
        .then(() => {
          this.Form.documents = this.$deepCopy(this.selectedTemplate.details)
          const newArray = this.Form.documents.map(obj => ({
            ...obj, cd_input: obj.ci_input,
          }))
          this.Form.documents = this.$deepCopy(newArray)
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.setOverlayStatus(false)
        })
    },

    // フォームを初期化
    initForm() {
      const today = new Date()
      const twoWeeksLater = new Date()
      twoWeeksLater.setDate(today.getDate() + 14)

      // const year = twoWeeksLater.getFullYear()
      // const month = String(twoWeeksLater.getMonth() + 1).padStart(2, '0')
      // const day = String(twoWeeksLater.getDate()).padStart(2, '0')
      // this.Form.cont_date = `${year}-${month}-${day}`
      this.Form.cont_date = today.toISOString().substring(0, 10)
      this.Form.cont_edd = twoWeeksLater.toISOString().substring(0, 10)
      this.Form.cont_ssd = twoWeeksLater.toISOString().substring(0, 10)

      this.Form.cont_abroad = 1
      this.Form.ccy_id = 1
      this.Form.ic_id = 1

      console.log('edd,ssd:', this.Form.cont_edd, this.Form.cont_ssd)
    },
    addContainer(Form) {
      const detailId = Form.containers.length + 1
      Form.containers.push({
        id: detailId,
        detail_id: detailId,
        product_id: '',
        cntr_no: '',
        seal_no: '',
        cntr_bl: '',
        cntr_weight: 0,
        cntr_amount: 0,
        showon: true,
        details: [],
      })
    },
    addPellet(item) {
      const detailId = item.details.length + 1
      item.details.push({
        cntr_did: detailId,
        pellet_id: '',
        product_name: '',
        cntr_dtl_fcb: '',
        cntr_dtl_weight: '',
        cntr_dtl_price: '',
        cntr_dtl_amount: '',
      })
    },
    deletePellet(item, items) {
      if (items.indexOf(item) !== -1) {
        items.splice(items.indexOf(item), 1)
        const pelletList = []
        items.forEach((v, i) => {
          pelletList[i] = v
          pelletList[i].cntr_did = i + 1
        })
      } else {
        console.log('deletePellet-----> item does not exist')
      }
    },
    removeContainer(item) {
      // console.log(this.Form.containers.indexOf(item))
      if (this.Form.containers.indexOf(item) !== -1) {
        this.Form.containers.splice(this.Form.containers.indexOf(item), 1)
        const containerList = []
        this.Form.containers.forEach((v, i) => {
          containerList[i] = v
          containerList[i].detail_id = i + 1

          // console.log(i, v.detail_id, v.detail_id)
        })
      } else {
        console.log('removeContainer-----> item does not exist')
      }
    },
    getaddCusList(selectCustomer) {
      this.selectedCustomer = selectCustomer
      if (selectCustomer.length === 1) {
        this.Form.cust_abbr = selectCustomer[0].cust_abbr
        this.Form.cust_id = selectCustomer[0].cust_id
        this.Form.cust_name = selectCustomer[0].cust_name
        this.Form.cust_name_eng = selectCustomer[0].cust_name_eng
        this.Form.cust_abbr = selectCustomer[0].cust_abbr
      } else {
        this.Form.cust_abbr = ''
        this.Form.cust_id = ''
        this.Form.cust_name = ''
        this.Form.cust_name_eng = ''
        this.Form.cust_abbr = ''
      }
    },
    menuUp(item) {
      const index = this.Form.containers.indexOf(item)
      this.Form.containers[index].showon = !this.Form.containers[index].showon

      // this.$nextTick(() => {
      //   const index = this.Form.containers.indexOf(item)
      //   console.log('index', this.Form.containers.indexOf(item))
      //   console.log('bfo', this.Form.containers[index].showon)
      //   console.log('itembfo', this.Form.containers[index])
      //   this.Form.containers[index].showon = !this.Form.containers[index].showon
      //   console.log('after', this.Form.containers[index].showon)
      //   console.log('itemafter', this.Form.containers[index])
      // })
    },

    pelletIdChange(item, items, arrs) {
      const index1 = items.indexOf(item)
      this.Form.containers[Number(arrs.detail_id - 1)].details[index1].product_name = this.pelletNameEn[item.pellet_id]
    },
    change(item) {
      console.log('item', item)
      this.loadTemp(item)
    },
    page1ErrorTouch() {
      this.$v.Form.cust_abbr.$touch()
      this.$v.Form.cont_date.$touch()
      this.$v.Form.ic_id.$touch()
      this.$v.Form.ccy_id.$touch()
      this.$v.Form.dest_id.$touch()
    },
    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },
    jumpToListPage() {
      this.$router.push({
        path: '/contract-list',
        query: this.$route.params.query ?? {
        },
      })
    },
    toPage2(Form) {
      console.log('submitTo', Form)
      this.submitStatus = true
      this.page1ErrorTouch()
      if (!this.$v.Form.cust_abbr.$invalid
      && !this.$v.Form.cont_date.$invalid
      && !this.$v.Form.ic_id.$invalid
      && !this.$v.Form.ccy_id.$invalid
      && !this.$v.Form.dest_id.$invalid) {
        this.apierror.messages = []
        this.page = 2
        this.submitStatus = false
        window.scrollTo(0, 0)
      } else {
        console.log('error submit!!')
        window.scrollTo(0, 0)
        this.apierror.messages = []
        this.apierror.status = 'error'
        this.apierror.messages.push('赤い部分の情報を修正してください')
        this.submitStatus = false
      }
    },
    checkPelletDuplicate() {
      this.apierror.messages = []
      for (let i = 0; i < this.Form.containers.length; i += 1) {
        const pelletIds = this.Form.containers[i].details.map(v => v.pellet_id)
        const hasDuplicate = new Set(pelletIds).size !== pelletIds.length

        if (hasDuplicate) {
          this.apierror.messages = ['ペレットIDが重複です。']
          this.apierror.status = 'error'
        }
      }
    },
    toPage3(Form) {
      console.log('Form', Form)
      if (Form.containers.length !== 0) {
        for (let i = 0; i < Form.containers.length; i += 1) {
          if (Form.containers[i].details.length === 0) {
            this.detailErrors = true
          }
          if (Form.containers.every(obj => Array.isArray(obj.details) && obj.details.length > 0)) {
            this.submitStatus = true
            this.$v.$touch()
            this.checkPelletDuplicate()
            if (this.apierror.messages.length === 0 && !this.$v.$invalid) {
              this.apierror.messages = []

              // this.loadTemp(1)
              this.page = 3
              this.submitStatus = false
              window.scrollTo(0, 0)
            } else {
              console.log('error submit!!')
              window.scrollTo(0, 0)
              this.apierror.status = 'error'
              if (this.apierror.messages.length === 0) {
                this.apierror.messages.push('赤い部分の情報を修正してください')
              }
              this.submitStatus = false
            }
          }
        }
      } else {
        this.containersErrors = true
      }
    },
    submit(Form) {
      this.submitStatus = true
      this.Form.packing_date = `${this.packing_date} ${this.packing_hour}:${this.packing_minute}:00`
      this.createContract(Form)
        .then(() => {
          this.jumpToListPage()
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          }
          console.log('apierror', this.apierror)
        })
        .finally(() => {
          this.submitStatus = false
        })
    },
  },
}
</script>

<style>
.ql-editor {
  min-height: 120px;
}
</style>
